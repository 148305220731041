import React from 'react'
import Vector from "../../assets/Vector.png"
import Vector1 from "../../assets/Vector_1.png"
import Vector2 from "../../assets/Vector_2.png"


const Card = ({icon, heading, sortdisc })=>{
    return<div className='relative h-full max-w-72 shrink-0'>
    <div className='p-2 h-14 w-14 m-2 bg-grayShade rounded-md flex justify-center items-center'>
        <img src={icon} alt="." />
    </div>
   
    <h1 className='font-semibold text-2xl m-1'>{heading}</h1>
    <p className='m-1'>{sortdisc}</p>
    </div>

}

function Whytochoose() {
  return (
    <div className='  bg-grayShade bg-opacity-20 p-5 md:p-10 relative'>
        <h4 className='font-bold text-2xl'>Why businesses love us ?</h4>
        <div className='flex flex-col md:flex-row justify-between h-full md:mt-6'>
        <Card icon={Vector} heading="Customization" sortdisc=" We understand that one size does not fit all. Our solutions are tailored to meet your unique requirements and objectives."></Card>
        <Card icon={Vector1} heading="Innovation" sortdisc="We stay ahead of the curve with the latest technologies and trends to ensure your digital presence remains cutting-edge."  ></Card>     
        <Card icon={Vector2} heading="Collaboration" sortdisc="We believe in transparent communication and collaboration every step of the way, keeping you informed throughout the process."  ></Card>   
        </div>
    </div>
  )
}

export default Whytochoose

