import React from 'react';
import { our_mission_section } from '../../util/images';
import { Link } from 'react-router-dom';

const MissionSection = () => {
  return (
    <section className="bg-white text-black flex items-center justify-center text-center md:text-left md:px-16 ">
      <div className="container mx-auto  flex  flex-col-reverse  md:flex-row items-center justify-between">
      <div className="  flex md:w-1/2 justify-center p-5 mb-8 my-5 md:my-0  md:mb-0 md:p-24">
        <img src={our_mission_section} alt="Team promising" className="rounded-lg shadow-lg w-full  h-auto"  />
      </div>
        <div className=" flex-1 md:w-1/2 pl-0  md:px-20">
          <h1 className="md:text-[40px] text-3xl leading-0 md:font-bold mb-4 ">Our mission is to forge future for businesses through great solutions</h1>
          <p className="text-lg mb-6">
            We have exceptional service, and a passion for innovation. We strive to build long-lasting relationships with our clients, understanding their unique needs and goals to deliver personalized support. We believe that true success comes from fostering long-lasting relationships with our clients. We take the time to understand your company's unique goals, challenges, and target audience.
          </p>
          <Link to="/contactUs" className="bg-skyblue text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 font-semibold">
            GET NOW
          </Link>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
