import React from 'react'
import HeroSection from './Herosection'
import MissionSection from './OurMissionSection'
import PromisSection from './PromisSection'
import Whytochoose from '../Services/WhyToChooseUs'
import WhatWeOfferSection from '../AboutUs/WhatWeOfferSection'

function Home() {
  return (
    <>
    <HeroSection />
     <MissionSection />
     <PromisSection />
     <Whytochoose/>
     <WhatWeOfferSection/>
     
     </>
  )
}

export default Home