import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



import Navbar from "./component/NavBar/Navbar";
import Services from "./pages/Services/Services";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/ContactUs/ContactUs";
import Portfolio from "./pages/Portfolio/Portfolio";
import AboutUs from "./pages/AboutUs/AboutUs";
import Footer from "./component/Footer/Footer";
import Designing from "./pages/Design/Design";
import Development from "./pages/development/Development";
import ConsultationServices from "./pages/development/Consultation";
import PrivacyPolicy from "./pages/policyPages/PrivacyPolicy";
import CookiePolicy from "./pages/policyPages/cookiesPolicy";
import Disclaimer from "./pages/policyPages/Disclaimer";
import SecurityPolicy from "./pages/policyPages/SecurityPolicy";
import ScrollToTop from "./util/ScrollToTop";
import TermsAndConditions from "./pages/policyPages/TermsAndCondition";


const App = () => {
 return (
   <Router>
     <Navbar />  
     <ScrollToTop />
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/services" element={<Services />} />
         <Route path="/portfolio" element={<Portfolio />} />
         <Route path="/aboutUs" element={<AboutUs />} />
         <Route path="/contactUs" element={<ContactUs />} />     
         <Route path="/design" element={<Designing />} />     
         <Route path="/development" element={<Development />} />     
         <Route path="/consultation" element={<ConsultationServices />} />     
         <Route path="/privacypolicy" element={<PrivacyPolicy />} />     
         <Route path="/cookiepolicy" element={<CookiePolicy />} />     
         <Route path="/disclaimer" element={<Disclaimer />} />     
         <Route path="/securitypolicy" element={<SecurityPolicy />} />
         <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
       </Routes>
   <Footer/>
   </Router>
 );
};

export default App;