import React from "react";
import PageHeader from "../../styles/PageHeader";
import {
  portfolio_header,
  post1,
  post2,
  post3,
  post4,
  post5,
  post6,
} from "../../util/images";

function Portfolio() {
  const posts = [
    { id: 1, title:"Social Media Content Creator", image: post1, shortDisc: "ML-powered social media management platform simplifies content creation, scheduling, and audience engagement, offering an all-in-one solution for businesses navigating the challenges of diverse platforms and large audiences." },
    { id: 2, title:"Real Estate Solution",image: post2, shortDisc: "REDHANDED is an ML-Powered SaaS-based platform where real estate owners and entrepreneurs can transact independently of a broker in a transparent, efficient, and cost-effective manner." },
    { id: 3,title:"Learning Management System", image: post3, shortDisc: "A dynamic learning management system equipped with interactive tools and virtual classrooms empowering both teachers and learners to deliver." },
    { id: 4, title:"Business Management System",image: post4, shortDisc: "A One-Stop-Shop for all the planning. With VisionCollab’s all-in-one tool for agile management, allocate tasks and manage them till completion." },
    { id: 5,title:"E-commerce Website", image: post5, shortDisc: "It is a sophisticated system that enables Product Management, Inventory Management, Shipping Options, or Discounts and Promotions, Search Engine Optimization." },
    { id: 6, title:"UI/UX Design",image: post6, shortDisc: "That UI/UX design consist on the Interactive Elements, Loading Indicators, Micro-interactions, Clear Error Messages and User Accounts and Dashboards." },
  ];

  const Card =({title, shortDisc,image})=>{
    return(  <div class="max-w-sm rounded-lg shadow">
      <img class="rounded-t-lg" src={image} alt="..." />

      <div class="p-5">
        <h5 class="mb-2 text-2xl font-bold tracking-tight ">
         {title}
        </h5>

        <p class="mb-3 font-normal ">
   {shortDisc}
        </p>
        <a
          href="/contactUs"
          class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Know More
          <svg
            class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>)
  }

  return (
    <>
      <PageHeader imageUrl={portfolio_header} headingText="Portfolio" />
      <section className="p-6">
        <h1 className=" font-bold md:text-4xl md:my-10 tracking-widest">
          Witness The Impact From The Successful Implementation Of Our Projects.
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-9 md:mt-20">

        {posts.map((item)=>{
          return <Card key={item.id} shortDisc={item.shortDisc} title={item.title} image={item.image} />
        })}

        </div>
      </section>

    
    </>
  );
}

export default Portfolio;
