import React from 'react'
import PageHeader from '../../styles/PageHeader'
import { aboutus_header, discover_our_story, our_journy_section } from '../../util/images'
import WhatWeOfferSection from './WhatWeOfferSection'
import { Link } from 'react-router-dom'

function AboutUs() {
  return (
   <>
    <PageHeader imageUrl={aboutus_header} headingText="About Us"/>
    <section className="bg-white text-black flex items-center justify-center md:px-16 ">
      <div className="container mx-auto  flex flex-col md:flex-row items-center justify-between">
        <div className="  flex w-1/2 justify-center mb-8 md:mb-0 p-24">
          <img src={our_journy_section} alt="Team working" className="rounded-lg shadow-lg w-full  h-auto"  />
        </div>
        <div className=" flex-1 md:w-1/2 pl-0 px-20">
          <h1 className="text-[40px] leading-0 font-bold mb-4 ">Service Description</h1>
          <p className="text-lg mb-6">
          At Sarmie, we take a comprehensive approach to helping you achieve your online goals. We offer a wide range of services, from crafting strategic marketing plans to creating beautiful and functional websites and applications. Our team of experts is passionate about using the latest technologies to deliver results.
          Let Sarmie be your partner on the road to digital success.
          </p>
          <Link to="/contactUs"  className="bg-skyblue text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 font-semibold">
            GET NOW
          </Link>
        </div>

      </div>
   
    </section>
    <section className="bg-white text-black flex items-center justify-center md:px-16 ">
    <div className="container mx-auto  flex flex-col md:flex-row items-center justify-between">
     
      <div className=" flex-1 md:w-1/2 pl-20 px-20">
        <h1 className="text-[40px] leading-0 font-bold mb-4 ">We promise to deliver high-quality services that exceed our clients' expectations</h1>
        <p className="text-lg mb-6">
        Our commitment to excellence, creativity, and reliability sets us apart in the industry. We understand the dynamic nature of today's market, and we're here to ensure that your business not only survives but thrives. Our team is dedicated to delivering nothing but the best. We take pride in our work and strive to exceed our clients' expectations every step of the way.
        </p>
        <Link to="/contactUs" className="bg-skyblue text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 font-semibold">
          GET NOW
        </Link>
      </div>
      <div className="  flex w-1/2 justify-center mb-8 md:mb-0 p-24">
        <img src={discover_our_story} alt="Team promising" className="rounded-lg shadow-lg w-full  h-auto"  />
      </div>
    </div>
  </section>
  <WhatWeOfferSection />
   </>
  )
}

export default AboutUs