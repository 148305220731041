import React from 'react'
import PageHeader from '../../styles/PageHeader'
import { service1, service2, service3, service4, service_description_section_image, services_header, service5 } from '../../util/images'
import Whytochoose from './WhyToChooseUs';
import { Link } from 'react-router-dom';

function Services() {
  const posts = [
    { id: 1, title:"Marketing", image: service1, shortDisc: "Unlock the full potential of your brand with our expert marketing services. Our team crafts tailored strategies to elevate your online presence, increase brand awareness, and drive meaningful engagement." },
    { id: 2, title:"Consultation",image: service2, shortDisc: "Partner with our seasoned consultants to navigate the complexities of the digital landscape. Whether you're a startup seeking guidance or an established enterprise aiming to refine your strategy." },
    { id: 3,title:"Design", image: service3, shortDisc: "Stand out in a crowded digital marketplace with captivating design solutions. Our creative team specializes in crafting visually stunning and user-centric designs that captivate audiences and leave a lasting impression." },
    { id: 4, title:"Development",image: service4, shortDisc: "Empower your digital presence with our expert development services. We specialize in building robust, scalable, and feature-rich websites, web applications, and mobile apps tailored to your specific needs." },
    { id: 4, title:"Hosting Support",image: service5, shortDisc: "Experience top-tier hosting support with us! Our expert team is available 24/7, ready to resolve any issues quickly. We ensure your website runs smoothly with minimal downtime. Enjoy personalized assistance and proactive monitoring to keep everything on track. Trust us for reliable, worry-free hosting support." },
  
  ];

  const ServiceCard =({title, shortDisc,image})=>{
    return(  <div class="max-w-sm rounded-lg shadow p-5 md:p-0">
      <img class="rounded-t-lg" src={image} alt="..." />

      <div class="p-5">
        <h5 class="mb-2 text-2xl font-bold tracking-tight ">
         {title}
        </h5>

        <p class="mb-3 font-normal ">
   {shortDisc}
        </p>
        <a
          href="/contactUs"
          class="inline-flex items-center px-3 py-2 text-sm  font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Know More
          <svg
            class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>)
  }
  return (
   <>
   <PageHeader imageUrl={services_header} headingText="Services" />
   <section className="bg-white text-black flex items-center justify-center md:px-16 ">
      <div className="container mx-auto  flex flex-col text-center md:text-left md:flex-row items-center justify-between">
        <div className="  flex md:w-1/2 justify-center my-8 md:mb-0 p-5 md:p-24">
          <img src={service_description_section_image} alt="Team working" className="rounded-lg shadow-lg w-full  h-auto"  />
        </div>
        <div className=" flex-1 md:w-1/2 md:pl-0 px-10 md:px-20">
          <h1 className="md:text-[40px] text-3xl  leading-0 font-bold mb-4 ">Service Description</h1>
          <p className="text-lg mb-6">
          At Sarmie, we take a comprehensive approach to helping you achieve your online goals. We offer a wide range of services, from crafting strategic marketing plans to creating beautiful and functional websites and applications. Our team of experts is passionate about using the latest technologies to deliver results.
          Let Sarmie be your partner on the road to digital success.
          </p>
          <Link to="/contactUs" className="bg-skyblue text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 font-semibold">
            GET NOW
          </Link>
        </div>
      </div>
    </section>
  
        <div className="grid grid-cols-1 md:grid-cols-3 gap-9 mt-10 md:px-16">
        {posts.map((item)=>{
          return <ServiceCard key={item.id} shortDisc={item.shortDisc} title={item.title} image={item.image} />
        })}
        </div>
        <Whytochoose/>


   </>
  )
}

export default Services