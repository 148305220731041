import React from 'react'
import PageHeader from '../../styles/PageHeader';
import { development } from '../../util/images';

function Development() {
    return (
        <> <PageHeader imageUrl={development} headingText="Development"/>
    <div className="p-8 bg-white text-gray-800">
      <section className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Why Development Matters</h2>
        <p className="text-lg">
          In the digital era, a robust and high-performing website or application is essential for business success. At <span className="font-semibold">Sarmie</span>, our development team is proficient in creating dynamic, scalable, and secure digital solutions tailored to your business needs. We ensure that our development practices adhere to industry standards and leverage the latest technologies.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Development Process</h3>
        <ul className="list-disc list-inside text-lg">
          <li><span className="font-semibold">Planning & Strategy:</span> Detailed project planning and roadmap creation.</li>
          <li><span className="font-semibold">Design & Architecture:</span> Crafting system architecture and user interfaces.</li>
          <li><span className="font-semibold">Development:</span> Coding and integration of functionalities.</li>
          <li><span className="font-semibold">Testing & Quality Assurance:</span> Rigorous testing to ensure performance and security.</li>
          <li><span className="font-semibold">Deployment & Maintenance:</span> Launch and ongoing maintenance services.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Services</h3>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">Web Development</h4>
          <p className="text-lg">
            We specialize in custom website development, ensuring that your site is not only visually appealing but also functional and secure. Our services include CMS integration, allowing you to manage your content easily. Additionally, we develop progressive web apps (PWAs) that offer an app-like experience on the web, enhancing user engagement.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">E-commerce Solutions</h4>
          <p className="text-lg">
            Our e-commerce solutions are designed to help you sell more online. We create custom e-commerce websites that are tailored to your business needs. We integrate popular platforms like Shopify and WooCommerce, and provide payment gateway integration to ensure a smooth and secure checkout process for your customers.
          </p>
        </div>
        <div>
          <h4 className="text-xl font-semibold">Custom Applications</h4>
          <p className="text-lg">
            From enterprise applications to mobile apps, our custom application development services cater to a wide range of business needs. We develop applications that are robust, scalable, and easy to use. Our services include API development and integration, ensuring that your applications can communicate seamlessly with other systems.
          </p>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-semibold mb-4">Benefits to Clients</h3>
        <ul className="list-disc list-inside text-lg">
          <li>Custom Solutions Tailored to Business Needs</li>
          <li>Enhanced Security and Performance</li>
          <li>Scalability to Support Business Growth</li>
          <li>Seamless Integration with Existing Systems</li>
        </ul>
      </section>
    </div>
        </>
    );
}

export default Development