import React from 'react';

const SecurityPolicy = () => {
  return (
    <div className="p-8 bg-gray-100 text-gray-800">
      <section className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Security Policy</h2>
        <p className="text-lg leading-relaxed">
          Tata Consultancy Services is an IT services, consulting and business solutions organization offering a consulting-led, cognitive powered, integrated portfolio of business, technology and engineering services and solutions delivered through its unique Location Independent Agile™ delivery model. As part of its operations, Sarmie has access to business-sensitive information (including personal information), the protection of which is crucial to Sarmie and its clients’ business interests.
        </p>
        <p className="text-lg leading-relaxed">
          Our Security Vision is “To make Sarmie reliable, resilient and immune to the existing and evolving volatile environment of constant changes, accidents, attacks and failures.”
        </p>
        <p className="text-lg leading-relaxed">
          This Security Policy reiterates our commitment to protect all the information and assets that we own or are responsible for; thus, ensuring an efficient, safe and secure working environment for Sarmie and its customers.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Security Objectives</h3>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Protection of information and assets against unauthorized access by deploying adequate security controls covering physical, logical and personnel security</li>
          <li>Compliance to legal and statutory / regulatory requirements across its global operations</li>
          <li>Continuity of operations in line with business requirements and obligations to its stakeholders</li>
          <li>Inclusion of security responsibilities of various departments / individuals to adhere to this Policy</li>
          <li>Adequate security awareness and competence among associates at all levels to fulfill these responsibilities</li>
          <li>Avenues for associates and other stakeholders to report security weaknesses, violations or disruption of services</li>
          <li>A robust response framework to handle security weaknesses, violations or disruption of services</li>
          <li>Governance of security performance against appropriate targets and objectives, enabling continuous improvements</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Support and Review</h3>
        <p className="text-lg leading-relaxed">
          This Policy will be supported by Standards, Procedures and Guidelines (Security Management System) and will be made available to all stakeholders who are expected to contribute towards the effective implementation and deployment of these security norms. The Security Management System will be periodically reviewed to ensure its continuing applicability and relevance to our operations and evolving stakeholder expectations.
        </p>
        <p className="text-lg leading-relaxed">
          This Policy will extend to Sarmie globally and its wholly owned subsidiaries in all geographies, and will be applicable to all its associates, business associates and external parties having access and usage rights to its infrastructure, IT systems and/or Information resources. Consequences arising out of violations or contravention to any of the objectives of this Policy will be equally applicable to all associates of Sarmie and its wholly owned subsidiaries globally and external parties.
        </p>
      </section>
    </div>
  );
};

export default SecurityPolicy;
