import React from 'react';
import PageHeader from '../../styles/PageHeader';
import { design } from '../../util/images';

const Designing = () => {

    return(<>
     <PageHeader imageUrl={design} headingText="Design"/>
    <div className="p-8 bg-white text-gray-800">
      <section className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Design Services</h2>
        <p className="text-lg leading-relaxed">
          At <span className="font-semibold">Sarmie</span>, our design team is dedicated to creating visually stunning and user-friendly designs that effectively communicate your brand message. We combine creativity with technical expertise to deliver designs that not only look great but also perform exceptionally well.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Design Process</h3>
        <ul className="list-disc list-inside text-lg space-y-2">
          <li><span className="font-semibold">Research & Discovery:</span> Understanding your brand, audience, and design requirements.</li>
          <li><span className="font-semibold">Concept Development:</span> Creating initial design concepts and mood boards.</li>
          <li><span className="font-semibold">Design Execution:</span> Developing detailed designs and prototypes.</li>
          <li><span className="font-semibold">Feedback & Revisions:</span> Incorporating feedback and refining the designs.</li>
          <li><span className="font-semibold">Final Delivery:</span> Providing the final designs and assets ready for implementation.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Services</h3>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">Brand Identity Design</h4>
          <p className="text-lg leading-relaxed">
            We create cohesive brand identities that include logos, color schemes, typography, and brand guidelines. Our designs ensure that your brand stands out and is easily recognizable.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">UI/UX Design</h4>
          <p className="text-lg leading-relaxed">
            Our UI/UX design services focus on creating intuitive and engaging user interfaces for websites and applications. We prioritize user experience to ensure that your digital products are easy to use and meet the needs of your audience.
          </p>
        </div>
        <div>
          <h4 className="text-xl font-semibold">Graphic Design</h4>
          <p className="text-lg leading-relaxed">
            From marketing materials to social media graphics, our graphic design services cover a wide range of needs. We create visually appealing designs that help you communicate your message effectively.
          </p>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-semibold mb-4">Benefits to Clients</h3>
        <ul className="list-disc list-inside text-lg space-y-2">
          <li>Unique and Memorable Brand Identity</li>
          <li>Improved User Experience</li>
          <li>Engaging and Effective Visual Communication</li>
          <li>Consistent Design Across All Platforms</li>
          <li>Professional and High-Quality Designs</li>
        </ul>
      </section>
    </div></>)
};

export default Designing;
