import React from 'react';
import { herosection_image } from '../../util/images';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <section className="bg-black text-white flex items-center justify-center w-full py-10">
      <div className="container mx-auto flex  justify-between text-center md:text-left flex-col md:flex-row items-center md:p-16">
        <div className="md:w-1/2 mb-8 md:mb-0 px-5 md:px-0">
          <h1 className=" text-skyblue text-3xl text-center  md:text-left md:text-5xl font-bold mb-4">Grow Your Business:</h1>   
     
          <h1 className=" text-white text-3xl text-center md:text-left md:text-5xl font-bold mb-4">With <span >INDIA's</span> <span className='text-green-400'>Leading </span> </h1>
          <h1 className=" text-skyblue text-3xl text-center md:text-left  md:text-5xl font-bold mb-4">Digital Innovators </h1>   
        
          <p className="text-center  p-5 md:text-left md:p-0  md:text-lg mb-6 ">
            We help businesses achieve their goals through strategic marketing, creative design, and expert development. 
            In today's competitive market, having a strong online presence and a clear brand identity is crucial for success.
          </p>
          <Link to="/contactUs" className="bg-skyblue md:text-2xl  text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300">
            GET NOW
          </Link>
        </div>
        <div className="md:w-1/3">
          <img src={herosection_image} alt="Office environment" className="rounded-lg shadow-lg" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
