import React from 'react'
import { TbUserCheck } from "react-icons/tb";
import { LiaConnectdevelop } from "react-icons/lia";
import { RiTimeZoneFill } from "react-icons/ri";


const Card = ({icon, heading, sortdisc })=>{
    return<div className='relative h-full max-w-72 shrink-0'>
    <div className='p-2 h-14 w-14 m-2 bg-grayShade flex justify-center items-center'>
        {icon}
    </div>
   
    <h1 className='font-semibold text-2xl m-1'>{heading}</h1>
    <p className='m-1'>{sortdisc}</p>
    </div>

}

function WhatWeOfferSection() {
  return (
    <div className='  bg-grayShade bg-opacity-20 p-5 md:p-10 relative'>
        <h4 className='font-bold text-2xl'>What we Offer ?</h4>
        <div className='flex flex-col md:flex-row justify-between h-full md:mt-6'>
        <Card icon={<TbUserCheck className=' text-4xl' />} heading="Expertise" sortdisc="Our team is meticulously selected and nurtured to deliver premium services. Fueled by passion for our work, we continuously enhance our skill set."></Card>
        <Card icon={<LiaConnectdevelop className=' text-4xl'/>} heading="Technicality" sortdisc="Our professionalism guarantees your success. Trust that your project is secure with us, ensuring peace of mind."  ></Card>     
        <Card icon={<RiTimeZoneFill className=' text-4xl'/>} heading="Availability" sortdisc="We prioritize your satisfaction, welcoming your feedback and being accessible via phone, email, or in-person, whether it's at your office or ours."  ></Card>   
        </div>
    </div>
  )
}

export default WhatWeOfferSection

