import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { sarmie_blue_logo } from '../../util/images';

const Footer = () => {
  return (
    <footer className= " relative bg-black text-white p-5 md:px-16  pt-20  mt-6 ">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
            <div className='flex justify-center flex-col items-center -ml-5 md:-ml-4
             w-24'> 
        <NavLink to="/" className="flex  items-center">
          <img src={sarmie_blue_logo} alt="logo" className=" h-8 w-10 md:h-8 md:w-10 " />
        </NavLink>
          <h2 className="text-xl font-bold mb-4">Sarmie</h2>
          </div>
          <Link to="/services" className="block mb-2">Services</Link>
          <Link to="/portfolio" className="block mb-2">Portfolio</Link>
          <Link to="/aboutUs" className="block mb-2">About us</Link>
          <Link to="/contactus" className="block">Contact</Link>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4 text-skyblue">Services</h2>
          {/* <Link to="/marketing" className="block mb-2">Marketing</Link> */}
          <Link to="/consultation" className="block mb-2">Consultation</Link>
          <Link to="/design" className="block mb-2">Design</Link>
          <Link to="/development" className="block">Development</Link>
        
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4 text-skyblue">Email</h2>
          <p>support@sarmie.in</p>
          <h2 className="text-xl font-bold mt-8 mb-4 text-skyblue">Website</h2>
          <p>www.sarmie.in</p>
          <h2 className="text-xl font-bold mt-8 mb-4 text-skyblue">Address</h2>
          <p>ADARSH COMPLEX Hisar, Haryana 125006</p>
          {/* <h2 className="text-xl font-bold mt-8 mb-4">Follow us</h2> */}
          {/* <div className="flex space-x-4">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="text-white hover:text-blue-500" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-white hover:text-blue-500" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn className="text-white hover:text-blue-500" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-white hover:text-blue-500" />
            </a>
          </div> */}
        </div>
      </div>
      <div className="container mx-auto mt-12 border-t border-gray-700 pt-5 text-center">
        <p>© 2024 Sarmie</p>
        <div className="flex justify-center flex-wrap space-x-6 mt-2">
          <Link to="/privacypolicy">Privacy Policy</Link>
          <Link to="/cookiepolicy">Cookie Policy</Link>
          <Link to="/disclaimer">Disclaimer</Link>
          <Link to="/securitypolicy">Security Policy</Link>
          
          <Link to="/accessibility"></Link>
          <Link to="/terms_and_conditions">Terms & conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
