import React from 'react';
import PageHeader from '../../styles/PageHeader';
import { services_header } from '../../util/images';

const ConsultationServices = () => {
  return (

    <>
 <PageHeader imageUrl={services_header} headingText="Consultation" />
    <div className="p-8 bg-gray-50 text-gray-900">
      <section className="mb-8">
        <h2 className="text-3xl font-extrabold mb-4">Consultation Services</h2>
        <p className="text-lg leading-relaxed">
          At <span className="font-semibold">Sarmie</span>, our consultation services are designed to help you navigate the complexities of the digital landscape. Our expert consultants provide strategic guidance and actionable insights to help you achieve your business objectives.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Approach</h3>
        <ul className="list-disc list-inside text-lg space-y-2">
          <li><span className="font-semibold">Discovery & Analysis:</span> Understanding your business needs and current challenges.</li>
          <li><span className="font-semibold">Strategy Development:</span> Crafting customized strategies to meet your goals.</li>
          <li><span className="font-semibold">Implementation Planning:</span> Creating detailed plans for effective implementation.</li>
          <li><span className="font-semibold">Performance Monitoring:</span> Tracking progress and making necessary adjustments.</li>
          <li><span className="font-semibold">Continuous Improvement:</span> Ensuring ongoing optimization and growth.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Services</h3>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">Business Strategy Consultation</h4>
          <p className="text-lg leading-relaxed">
            We help you define a clear business strategy that aligns with your vision and goals. Our experts provide insights into market trends, competitive analysis, and growth opportunities.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-xl font-semibold">Digital Transformation</h4>
          <p className="text-lg leading-relaxed">
            Our digital transformation services guide you through the process of integrating digital technologies into all areas of your business, enhancing efficiency and delivering more value to your customers.
          </p>
        </div>
        <div>
          <h4 className="text-xl font-semibold">Technology Consultation</h4>
          <p className="text-lg leading-relaxed">
            We provide expert advice on selecting and implementing the right technologies to support your business objectives. Our services include IT infrastructure assessment, software selection, and system integration.
          </p>
        </div>
      </section>

      <section>
        <h3 className="text-2xl font-bold mb-4">Why Choose Us</h3>
        <ul className="list-disc list-inside text-lg space-y-2">
          <li>Expert Guidance from Industry Professionals</li>
          <li>Tailored Solutions to Meet Your Unique Needs</li>
          <li>Proven Track Record of Success</li>
          <li>Commitment to Continuous Improvement</li>
          <li>Focused on Delivering Measurable Results</li>
        </ul>
      </section>
    </div>
    </>
  );
};

export default ConsultationServices;
