import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { sarmie_blue_logo } from "../../util/images";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  return (
    <header className="sticky w-full top-0 left-0  z-50 p-5 md:px-10 bg-black">
      <nav className="flex items-center justify-between relative h-10 mx-4">
        <NavLink to="/" className="flex items-center">
          <img src={sarmie_blue_logo} alt="logo" className=" h-8 w-10 md:h-8 md:w-10 " />
        </NavLink>

        <div
          className={`fixed top-0 right-0 bg-black  backdrop-blur-lg backdrop-filter md:static md:bg-transparent md:backdrop-filter-none md:flex md:items-center md:space-x-8 transition-transform transform ${
            showMenu ? "translate-x-0" : "translate-x-full"
          } w-4/5 md:w-full h-full  md:h-auto md:translate-x-0`}
        >
          <div className=" w-full md:flex justify-between px-5 text-white">
          <ul className="flex flex-col items-center space-y-8 md:flex-row md:space-y-0 md:space-x-8 p-6 md:p-0  md:mt-0 font-semibold ">
            <li>
              <NavLink
                
                to="/"
             
                onClick={closeMenuOnMobile}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
            
                onClick={closeMenuOnMobile}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/aboutUs"
           
              
                onClick={closeMenuOnMobile}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/portfolio"
              
                onClick={closeMenuOnMobile}
              >
                Portflio
              </NavLink>
            </li>
         
          </ul>
          <ul className="flex flex-col items-center space-y-8 md:flex-row md:space-y-0 md:space-x-8  md:p-0 md:mt-0 font-semibold">
         
         
            <li>
              <NavLink
                to="/contactUs"
            
              >
               Contact Us
              </NavLink>
            </li>
          </ul>
          </div>
          <div
            className="absolute top-4 right-4 text-3xl text-white cursor-pointer md:hidden"
            onClick={toggleMenu}
          >
            <IoClose />
          </div>
        </div>

        <div className="text-3xl text-white cursor-pointer md:hidden" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
