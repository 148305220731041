import React, { useState } from 'react'
import PageHeader from '../../styles/PageHeader'
import { contact_us_header } from '../../util/images'
import { IoLocation } from 'react-icons/io5';

function ContactUs() {

  const [formData, setFormData] = useState({
    name: {
      value: "",
      error: false,
      errorMessage: ""
    },
    email: {
      value: "",
      error: false,
      errorMessage: ""
    },
    subject: {
      value: "",
      error: false,
      errorMessage: ""
    },
    message: {
      value: "",
      error: false,
      errorMessage: ""
    },
  });
  const [formError, setFormError] = useState({
    error: false,
    message: "",
  });

  function handleInput(e) {
    setFormData(prevFormData => ({
      ...prevFormData,
      [e.target.name]: { value: e.target.value, error: false }
    })
    )
  };

  async function handleFormSubmit(e) {
    console.log('form submit');
    console.dir(e);
    e.preventDefault();


    if (formData.name.error || formData.email.error || formData.subject.error || formData.message.error) {
      console.log("handling error");
      return;
    }

    const data = {
      name: formData.name.value,
      email: formData.email.value,
      subject: formData.subject.value,
      message: formData.message.value,
    }

    try {
      // await addDoc(collection(db, "contact"), data);
      setFormError({ error: false, message: "" });

      setFormData({
        name: {
          value: "",
          error: false,
          errorMessage: ""
        },
        email: {
          value: "",
          error: false,
          errorMessage: ""
        },
        subject: {
          value: "",
          error: false,
          errorMessage: ""
        },
        message: {
          value: "",
          error: false,
          errorMessage: ""
        },
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  function handleOnBlur(e) {
    if (e.target.value === "") {
      setFormData(prevFormData => ({
        ...prevFormData,
        [e.target.name]: { value: '', error: true, errorMessage: "fied is required" }
      }));
      return;
    } else if (!e.target.validity.valid) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [e.target.name]: { value: e.target.value, error: true, errorMessage: "invalid email" }
      }));
      return;
    }
  }

  return (<>
  <PageHeader headingText="Contact Us" imageUrl={contact_us_header}/>
 
     
   
      
     

     
       
          <div className="flex flex-col flex-start w-full p-16">
    <h1 className='md:text-4xl font-bold md:my-6'>Get in touch</h1>
    <h1 className=' font-light mb-5'>Don't let us go on sleep mode, message us</h1>

          
            <div className="flex md:justify-between max-md:flex-col">
              <div className="flex flex-col md:w-4/12 max-md:mt-4">
                <input className="border-2 p-4 border-black text-black py-4 text-sm" type="text" name="name" placeholder="First Name" value={formData.name.value} onChange={(e) => handleInput(e)} onBlur={(e) => handleOnBlur(e)} />
                <p className="text-red-400 text-sm h-4">{(formData.name.error) && formData.name.errorMessage}</p>
              </div>
              <div className="flex flex-col md:w-4/12 md:ml-2 max-md:mt-4">
                <input className="border-2 border-black p-4 py-4 text-sm" type="email" name="email" placeholder="Email" value={formData.email.value} onChange={(e) => handleInput(e)} onBlur={(e) => handleOnBlur(e)} />
                <p className="text-red-400 text-sm h-4">{(formData.email.error) && formData.email.errorMessage}</p>
              </div>
              <div className="flex flex-col md:w-4/12 md:ml-2 max-md:mt-4">
                <input className="border-2 border-black p-4 py-4 text-sm" type="text" name="subject" placeholder="Subject" value={formData.subject.value} onChange={(e) => handleInput(e)} onBlur={(e) => handleOnBlur(e)} />
                <p className="text-red-400 text-sm h-4">{(formData.subject.error) && formData.subject.errorMessage}</p>
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <textarea className="border-2 border-black p-4 py-4 text-sm" type="text" name="message" rows={8} placeholder="Message" value={formData.message.value} onChange={(e) => handleInput(e)} onBlur={(e) => handleOnBlur(e)} />
              <p className="text-red-400 text-sm h-4">{(formData.message.error) && formData.message.errorMessage}</p>
            </div>
            <button type="submit" onClick={(e) => handleFormSubmit(e)} className="lg:mb-8 rounded-sm bg-skyblue text-white uppercase  font-bold w-36 p-4 mt-4">Send</button>
          </div>

        
       
   
      
       
  
    </>
  );
}

export default ContactUs