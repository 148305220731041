import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="p-8 bg-gray-100 text-gray-800">
      <section className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Cookie Policy</h2>
        <p className="text-lg leading-relaxed">
          Sarmie uses cookies (small text files placed on your device) and similar technologies to provide our websites and to help collect data. The text in a cookie often consists of a string of numbers and letters that uniquely identifies your computer, but it can contain other information as well.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Use of Cookies and Similar Technologies</h3>
        <p className="text-lg leading-relaxed">
          Sarmie uses cookies and similar technologies for several purposes, which may include:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>
            <span className="font-semibold">Storing your Preferences and Settings:</span> Settings that enable our website to operate correctly or that maintain your preferences over time may be stored on your device.
          </li>
          <li>
            <span className="font-semibold">Sign-in and Authentication:</span> When you sign into our website using your credentials, we store a unique ID number, and the time you signed in, in an encrypted cookie on your device. This cookie allows you to move from page to page within the site without having to sign in again on each page. You can also save your sign-in information so you do not have to sign in each time you return to the site.
          </li>
          <li>
            <span className="font-semibold">Security:</span> We use cookies to detect fraud and abuse of our websites and services.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Does Sarmie Use Cookies for Analytics?</h3>
        <p className="text-lg leading-relaxed">
          When we send you a targeted email, subject to your preferences, which includes web beacons, cookies, or similar technologies, we will know whether you open, read, or delete the message.
        </p>
        <p className="text-lg leading-relaxed">
          When you allow the Performance Cookies to be dropped on your browser, we can associate cookie information with an identifiable individual. For example:
        </p>
        <p className="text-lg leading-relaxed">
          When you click a link in a marketing email you receive from us or fill up a form on our website, we will also use a cookie to log what pages you view and what content you download from our websites.
        </p>
        <p className="text-lg leading-relaxed">
          <span className="font-semibold">Combining and analyzing personal data:</span> We may combine data collected from performance cookies dropped on your browser. We use this information to improve and personalize your experience with our websites, provide you with content that you may be interested in, create marketing insights, and to improve our business and services.
        </p>
        <p className="text-lg leading-relaxed">
          In addition to the cookies Sarmie sets when you visit our websites, third parties may also set cookies when you visit Sarmie's sites. In some cases, that is because we have hired the third party to provide services on our behalf. We use cookies from Google reCAPTCHA to prevent abuse of the website and enhance its security (read Google’s privacy policy and reCAPTCHA for further information). Our website also uses the LinkedIn Insight Tag & Conversion Pixel for website tracking and analytics. It gives us additional insights to retarget website visitors with contextual messaging. It collects data regarding members’ visits to our website, including the URL, referrer, IP address, device and browser characteristics (User Agent), and timestamp. This enables us to understand more about members' interaction with our ads and how they engage with our website to take desirable actions like signing up for a newsletter or making a purchase. This feature will be enabled only if you allow the Targeting Cookies from LinkedIn to be dropped on your browser. For more details on this feature read LinkedIn’s Cookie Policy.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Cookie Details</h3>
        <p className="text-lg leading-relaxed">
          Some of the cookies we commonly use are listed below. This list is not exhaustive, but it is intended to illustrate the main reasons we typically set cookies. If you visit one of our websites, the site may set some or all of the following cookies:
        </p>
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border">Name</th>
              <th className="py-2 px-4 border">Description</th>
              <th className="py-2 px-4 border">Cookie Category</th>
              <th className="py-2 px-4 border">Domain</th>
              <th className="py-2 px-4 border">Expiry</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border">OptanonConsent</td>
              <td className="py-2 px-4 border">
                This cookie is set by the cookie compliance solution from OneTrust. It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the user's browser when consent is not given. The cookie has a normal lifespan of one year, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.
              </td>
              <td className="py-2 px-4 border">First Party - Strictly Necessary Cookies</td>
              <td className="py-2 px-4 border">sarmie.in</td>
              <td className="py-2 px-4 border">PERSISTENT</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">PHPSESSID</td>
              <td className="py-2 px-4 border">
                This cookie is generated by applications based on the PHP language. This is a general-purpose identifier used to maintain user session variables. It is normally a random generated number, how it is used can be specific to the site, but a good example is maintaining a logged-in status for a user between pages.
              </td>
              <td className="py-2 px-4 border">First Party - Strictly Necessary Cookies</td>
              <td className="py-2 px-4 border">sarmie.in</td>
              <td className="py-2 px-4 border">SESSION</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">JSESSIONID</td>
              <td className="py-2 px-4 border">
                General purpose platform session cookie, used by sites written in JSP. Usually used to maintain an anonymous user session by the server.
              </td>
              <td className="py-2 px-4 border">First Party - Strictly Necessary Cookies</td>
              <td className="py-2 px-4 border">sarmie.in</td>
              <td className="py-2 px-4 border">SESSION</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">OptanonAlertBoxClosed</td>
              <td className="py-2 px-4 border">
                This cookie is set by websites using certain versions of the cookie law compliance solution from OneTrust. It is set after visitors have seen a cookie banner and, in some cases, only when they actively close the notice down. It enables the website not to show the banner message more than once to a user. The cookie has a one-year lifespan and contains no personal information.
              </td>
              <td className="py-2 px-4 border">First Party - Strictly Necessary Cookies</td>
              <td className="py-2 px-4 border">sarmie.in</td>
              <td className="py-2 px-4 border">PERSISTENT</td>
            </tr>
            <tr>
              <td className="py-2 px-4 border">ApplicationGatewayAffinity</td>
              <td className="py-2 px-4 border">
                This cookie allows the browser to manage the browsing session and keep it alive for as long as it is needed.
              </td>
              <td className="py-2 px-4 border">First Party - Strictly Necessary Cookies</td>
              <td className="py-2 px-4 border">sarmie.in</td>
              <td className="py-2 px-4 border">SESSION</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Customise Cookies</h3>
        <p className="text-lg leading-relaxed">
          We may periodically update this Cookie Policy to reflect changes in our practices. If needed, in such situations we will prompt you to revisit your cookie settings and submit your preferences again.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How to Control Cookies Manually</h3>
        <p className="text-lg leading-relaxed">
          You can set your browser:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>To allow all cookies</li>
          <li>To allow only 'trusted' sites to send them</li>
          <li>To accept only those cookies from websites you are currently using.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          We recommend not to block all cookies because sarmie.in website uses them to work properly.
        </p>
        <p className="text-lg leading-relaxed">
          <span className="font-semibold">Google Chrome:</span>
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Click on the “Menu” tab in the upper-right corner and then click on “Settings”.</li>
          <li>To block cookies: Settings → Click on “Advanced” to expand → Under Privacy and Security, Click on “Content Settings” → Click on “Cookies” → To block cookies, Click on the toggle button next to this line “Allow sites to save and read cookie data (recommended)” → This will block the cookies.</li>
          <li>To check cookies: Settings → Click on “Advanced” to expand → Under Privacy and Security → Click on “Content Settings” → Click on “Cookies” → See all cookies and site data → Click on the website and check the cookies used in that particular site.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          <span className="font-semibold">Mozilla Firefox:</span>
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Click on the Menu tab in the upper-right corner → Click on Options → In the left side navigation, Click on Privacy and Security → Under History, Select “Use Custom setting for history” from the Drop down → Click on Show Cookies Buttons → Select the file which you want to remove and then click on remove selected button.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          <span className="font-semibold">Internet Explorer:</span>
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Open Internet Explorer → Click on Tools menu in the upper-right corner → Click on Internet Options → This will open a window with many tabs → Click on Privacy tab → Under Settings, move the slider to the top to block all cookies or to the bottom to allow all cookies → Then click Apply.</li>
          <li>Open Internet Explorer → Click on Tools menu in the upper-right corner → Click on Internet Options → This will open a window with many tabs → Click on Privacy tab → Click on Sites button → Enter site name and then click Allow or Block button → If user clicks block button, that website is not allowed to use cookies in IE → Then click Apply.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          <span className="font-semibold">Safari:</span>
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Open Safari → Click on Preferences from Safari menu → Go to Privacy tab → Click on “Remove all Website data” to remove all the stored data → Click Remove now button from the pop-up → Click on Details button under “Remove all Website data” → Select the sites you want to remove the data → Click Remove → Click Done.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          To find information relating to other browsers, visit the browser developer's website.
        </p>
      </section>
    </div>
  );
};

export default CookiePolicy;
