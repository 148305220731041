import React from 'react'
import { promis_section } from '../../util/images'
import { Link } from 'react-router-dom'

function PromisSection() {
  return (
    <section className="bg-white text-black flex text-center items-center justify-center md:px-16 ">
    <div className="container mx-auto  flex flex-col md:flex-row items-center justify-between">
     
      <div className=" flex-1 md:w-1/2 md:pl-20 px-2 md:px-20">
        <h1 className="md:text-[40px] text-3xl leading-0 md:font-bold  mb-4 ">We promise to deliver high-quality services that exceed our clients' expectations</h1>
        <p className=" mb-6 ">
        Our commitment to excellence, creativity, and reliability sets us apart in the industry. We understand the dynamic nature of today's market, and we're here to ensure that your business not only survives but thrives. Our team is dedicated to delivering nothing but the best. We take pride in our work and strive to exceed our clients' expectations every step of the way.
        </p>
        <Link  to="/contactUs" className="bg-skyblue text-white px-6 py-3 rounded hover:bg-blue-700 transition duration-300 font-semibold">
          GET NOW
        </Link>
      </div>
      <div className="  flex md:w-1/2 justify-center p-5 mb-8 my-5 md:my-0  md:mb-0 md:p-24">
        <img src={promis_section} alt="Team promising" className="rounded-lg shadow-lg w-full  h-auto"  />
      </div>
    </div>
  </section>
  )
}

export default PromisSection