import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-8 bg-gray-100 text-gray-800 md:top-5">
      <section className="mb-8">
        <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
        <p className="text-lg leading-relaxed">
          Your privacy is important to us and we believe it is important for you to know what personal data we, Sarmie, collect from you (and third parties), why we collect it, how we use it, and what rights you might be entitled to as a data subject or consumer.
        </p>
        <p className="text-lg leading-relaxed">
          Please note: all information in this privacy notice is applicable to you unless otherwise indicated based on your residency status. For additional terms which may be applicable to you based on your residency status, please refer to your country-specific terms at the end of this notice. In this notice, the term “personal data” is used to represent any information relating to an identified or identifiable person; country-specific notices might adopt a different terminology.
        </p>
        <p className="text-lg leading-relaxed">
          We encourage you to read this notice, together with any additional and more specific information we may provide to you on various occasions when we are collecting or processing personal data on Sarmie websites, products, applications, events, and initiatives so that you are aware of how and the purpose for which we are processing your personal data. (Please note: We issue a different privacy notice which applies where there is an employment relationship between Sarmie and its employees.)
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How We Will Use Your Personal Data</h3>
        <p className="text-lg leading-relaxed">
          We may collect different kinds of personal data in several different ways and use it for a number of different purposes:
        </p>
        <h4 className="text-xl font-semibold mt-4">To Ensure Access to Our Website and Online Services</h4>
        <p className="text-lg leading-relaxed">
          In general, you can visit sarmie.in and its affiliate websites on the World Wide Web without telling us who you are. Our web servers or affiliates who provide analytics and performance enhancement services may collect:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>IP addresses</li>
          <li>Operating system details</li>
          <li>Browsing details</li>
          <li>Device and connectivity details</li>
          <li>Language settings</li>
        </ul>
        <p className="text-lg leading-relaxed">
          This information is aggregated to measure the number of visits, average time spent on the site, pages viewed, and similar information. Sarmie uses this information to measure site usage, improve content, ensure safety and security, as well as enhance performance and user experience of the website. In some situations, you are required to register to access an authenticated area of the website; in these cases, we will also collect your username and password. We may also obtain data from third parties including:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Social networks, when you grant permission to us to access your data on one or more networks through our website.</li>
          <li>Service providers, who help us to determine a location based on your IP address in order to customize offerings and content to your location.</li>
          <li>Partners with whom we offer co-branded services or engage in joint marketing activities.</li>
        </ul>
        <p className="text-lg leading-relaxed">
          Sarmie uses cookies (small text files placed on your device) and similar technologies to facilitate the proper functioning of our websites and to help collect data. Please read our full cookie policy. Note that our websites may include links to websites of third parties whose privacy practices differ from those of Sarmie; if you provide personal data to any of those websites, your data is governed by their privacy statements.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">To Answer Your Queries, Support and Contact Requests</h3>
        <p className="text-lg leading-relaxed">
          If you contact us with queries, requests for more information about initiatives or products, or other generic support inquiries, we may need to process personal data about you such as:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Personal and contact details, like full name, company and role, email, and address</li>
          <li>Demographic data</li>
          <li>Qualifications and profession</li>
          <li>The content of your messages to us</li>
        </ul>
        <p className="text-lg leading-relaxed">
          For information about managing your contact data, email subscriptions, and promotional communications, please use the contact us form on the sarmie.in or affiliate websites where you provided your personal data.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Subscriptions to Our Promotional Communications</h3>
        <p className="text-lg leading-relaxed">
          If you sign up to receive marketing communications from Sarmie, we may send these by email, post, telephone, or any other means of communication. For information about managing your contact data, you may reach out to the contact details provided in the event privacy notice or in the emails you receive, or you may use the contact us form on the sarmie.in or affiliate websites where you provided your personal data.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">To Administer Events and Initiatives</h3>
        <p className="text-lg leading-relaxed">
          Sarmie frequently organizes events and initiatives, either free to join or by invitation only. This privacy notice applies to both participants and speakers, together with any other supplementary information provided in relation to each event. To allow participants to join the events (including communications pre and post event), we are required to collect and process a limited amount of information, such as:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>Full name</li>
          <li>Company, job title, and business email address</li>
          <li>Telephone number</li>
          <li>Location</li>
          <li>Pictures and video of you</li>
        </ul>
        <p className="text-lg leading-relaxed">
          Where hospitality service is provided, we may also collect, store, and process “special categories” of more sensitive personal data (SPI), such as dietary requirements or personal disabilities. This will be done only to the extent permitted by law. Events might be recorded, hence pictures and videos may be collected too. In such cases, you may be informed via a specific notice at the location of recording. This material may be used later by Sarmie for further compatible purposes in accordance with the notices you receive. Also, during the course of the events, we may collect data about you, which we later may use to contact you again in order to receive feedback and/or provide further commercial information about Sarmie; this may be done with your consent, wherever necessary. We may also obtain data from third parties including third-party event organizers where the event is sponsored by Sarmie. Organizers might provide the attendee list. In such cases, the privacy statement of those third parties to whom you provide your personal data will apply to you.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">To Promote the Sarmie Brand, Products, Initiatives, and Values with Marketing Communications</h3>
        <p className="text-lg leading-relaxed">
          Sarmie has a strong legitimate interest in promoting its brand, products, initiatives, and values. In order to further such goals, we process personal data about our business contacts including existing and potential Sarmie clients, third parties, and intermediaries Sarmie interacts with in the course of doing business. Sarmie may collect details about you including name, contact details, and other information such as your job title, employer, areas of business interest, and other business details. We may process such data using software or platforms which allow us to manage our client relationships. We collect such data directly or indirectly from you, or from third parties, such as business partners, data brokers, social networks, marketing companies, and publicly available sources such as social media sites where lawful to do so. In such cases, we will also comply with any additional restrictions imposed by the region in which your data was collected as well as the source of the data. Sarmie may also collect data from our email and calendar systems concerning interactions between Sarmie associates and contacts or third parties.
        </p>
        <p className="text-lg leading-relaxed">
          Your data is used by us in part for administering, managing, and developing our business and services; such as identifying existing and potential client business needs, analyzing and evaluating the strength of our interactions with certain business contacts, performing analytics including to produce metrics such as relationship maps for our business leadership, and limited profiling for the purposes of helping us to develop and offer appropriate products and services to existing and potential clients. Analytics may be performed by us using algorithms that help us analyze and rank interactions with you depending on interaction frequency and duration. We may also send you marketing communications and surveys to carry out market research or provide information to you about us and our services which we have reason to believe would be of interest to you in your professional capacity.
        </p>
        <p className="text-lg leading-relaxed">
          When reaching out to you, we may do so through different channels, such as email, phone calls, post, or any other means of communication such as through social media sites like LinkedIn. We will only send electronic marketing communications to business contacts in a business-to-business context, where we have your prior consent or where it is otherwise lawful to do so in the jurisdiction you are located in. You can opt out from receiving such communications at any time using the contact us form on sarmie.in or affiliate websites where you provided your personal data, writing to our Data Protection Officers (see section below) or by any other given method provided from time to time (for example: unsubscribe link included at the bottom of emails).
        </p>
        <p className="text-lg leading-relaxed">
          From time to time, we may use your name, quotes, pictures, or videos to promote and amplify Sarmie’s brand and activities. In any such case, you will receive appropriate additional information about the usage of your personal data.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Social Listening</h3>
        <p className="text-lg leading-relaxed">
          Sarmie uses the services of a third party to monitor (primarily by keyword searches) and analyze statements, comments, opinions, and similar interactions on social media channels about Sarmie made by Sarmie’s customers, employees, followers, fans, and others. Sarmie has a legitimate interest to do so in order to identify and assess what is being said about our brand on social media, to understand sentiment, intent, mood, market trends, and our stakeholders’ needs, to improve our services and to protect our brand.
        </p>
        <p className="text-lg leading-relaxed">
          We may collect publicly accessible data from social media networks and websites, including:
        </p>
        <ul className="list-disc list-inside text-lg space-y-2 ml-6">
          <li>User IDs</li>
          <li>Social network profile names and information</li>
          <li>Social network communications</li>
        </ul>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">To Manage, Administer and Fulfill the Obligations Under Contracts, and Regulations</h3>
        <p className="text-lg leading-relaxed">
          Where Sarmie is in a contractual relationship with you (other than an employment relationship), your employer, or your company, or is taking steps to enter into such a contractual relationship, we may need to process your personal data, usually limited to name, business contact details, and job title in order to enter into and/or fulfill the obligations arising from the same contract, such as providing you or your employer or company with the services you have requested or making use of the services that you or your employer or company are offering to us. We will also process such personal data for ancillary tasks related to our daily business activities, such as accounting, auditing, reporting (to regulators and authorities), and to comply with applicable regulations.
        </p>
        <p className="text-lg leading-relaxed">
          We will only use your personal data for the purposes for which we collected it unless we reasonably consider that we need to use it for another reason that is compatible with the original purpose and applicable law. If we need to use your personal data for an unrelated purpose, we will notify you and explain the legal basis that allows us to do so.
        </p>
        <p className="text-lg leading-relaxed">
          Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How We Will Share Your Personal Data</h3>
        <p className="text-lg leading-relaxed">
          We may share your data with third parties, including third-party service providers and other entities in the Sarmie group. Please note, Sarmie has not in the past, nor does it currently, under any circumstances sell your personal data to any third party. Further, Sarmie does not share your personal data with third parties for any additional purpose (as outlined below) unless required to fulfill a legal obligation or a legitimate business purpose where permitted by law.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Why Might You Share My Personal Data with Third Parties?</h3>
        <p className="text-lg leading-relaxed">
          We may share your personal data with third parties where required by law, where it is necessary for one of the activities mentioned above, or where we have another legitimate legal basis in doing so. We require third parties to respect the security of your data and to treat it in accordance with the law. Where required by law, we will request your consent before transferring data to third parties that are not part of the Sarmie group of companies.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Which Third-Party Service Providers Process My Personal Data?</h3>
        <p className="text-lg leading-relaxed">
          ”Third parties” includes third-party service providers (including contractors and designated agents) and other entities within our group. The following activities may be carried out by third-party service providers: hosting and other internet services, data storage and analytics, marketing research and campaign management, event organizers, and caterers. All our third-party service providers are required to take appropriate security measures to protect your personal data in line with our policies. We do not allow our third-party service providers to use your personal data for their own purposes. We only permit them to process your personal data for specified purposes and in accordance with our instructions and applicable law. We may also need to share your personal data with regulators or to otherwise comply with the law.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">When Might You Share My Personal Data with Other Entities in the Group?</h3>
        <p className="text-lg leading-relaxed">
          We may share your personal data with other entities in the Sarmie group as part of our regular reporting activities on company performance, in the context of a business reorganization or group restructuring exercise, for system maintenance support and hosting of data, and when it is required to do so in order to perform one of the activities listed above (see "How we will use information about you"). Other entities in the Sarmie group are required to take appropriate security measures to protect your personal data in line with our policies.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Transferring Your Personal Data Outside of Your Country of Residence</h3>
        <p className="text-lg leading-relaxed">
          We may transfer the personal data we collect about you to one or more countries outside of your country of residence or outside of the country in which you access this website, including India, in order to perform one of the activities listed above (see "How we will use information about you"). In such cases, we have put in place appropriate measures to ensure that your personal data will be secure according to the laws of the country in which you reside. If you require further information about these protective measures, you can request it from our Data Protection Officers (see contacts below) or send an email to support@sarmie.in.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How We Will Keep Your Information Safe</h3>
        <p className="text-lg leading-relaxed">
          We have put in place appropriate technical, organizational, and security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorized way, altered, or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
        </p>
        <p className="text-lg leading-relaxed">
          We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How Long We Will Keep Your Information</h3>
        <p className="text-lg leading-relaxed">
          We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Details of retention periods for different aspects of your personal data are available in our retention policy upon request from our Data Protection Officers (see contact details below). To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data, and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </p>
        <p className="text-lg leading-relaxed">
          In some circumstances, we may anonymize your personal data so that it can no longer be associated with you, in which case we may use such information without further notice to you.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">How to Contact Us</h3>
        <p className="text-lg leading-relaxed">
          If you have a privacy concern, complaint, or a question regarding this privacy statement, please direct it to the Head – Privacy Office of Sarmie at support@sarmie.in or contact us through the “Contact us” form on sarmie.in or affiliate websites where you provided your personal data, indicating your concern in detail.
        </p>
        <p className="text-lg leading-relaxed">
          For the purposes of the data processed under this statement, the controller or business/service provider for the data processing of your personal data collected through our websites is Sarmie, SHOP NO 114 ADARSH COMPLEX TCP, 2, RAILWAY ROAD, CANTT, Hisar, Haryana 125006. For all the other purposes indicated above, the controller or business/service provider is the same entity unless indicated otherwise in another privacy statement communicated in each situation.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Right to Withdraw Consent</h3>
        <p className="text-lg leading-relaxed">
          In the limited circumstances where you may have provided your consent to the collection, processing, and transfer of your personal data for a specific purpose, you may have the right to withdraw your consent for that specific processing at any time. To withdraw your consent, please contact our Privacy Office (see contacts below). Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legal basis for doing so.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Privacy Officer Contact Information</h3>
        <p className="text-lg leading-relaxed">
          We have appointed a Privacy Officer to oversee compliance of Sarmie with applicable data protection laws and with this privacy notice. If you have any questions about this privacy notice or how we handle your personal data, please contact the Privacy Officer at:
        </p>
        <p className="text-lg leading-relaxed">
          <strong>Privacy Officer:</strong> <a href="mailto:support@sarmie.in" className="text-blue-500">support@sarmie.in</a>
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Our Commitment to Privacy</h3>
        <p className="text-lg leading-relaxed">
          We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal data.
        </p>
      </section>

      <section>
        <h3 className="text-2xl font-semibold mb-4">Changes to This Privacy Notice</h3>
        <p className="text-lg leading-relaxed">
          We reserve the right to update this privacy notice at any time, and we will provide you with a new privacy notice when we make any substantial updates. We may also notify you in other ways from time to time about the processing of your personal data.
        </p>
        <p className="text-lg leading-relaxed">
          <strong>Major version label:</strong> 1.0
        </p>
        <p className="text-lg leading-relaxed">
          <strong>Major version date:</strong> 20 June 2024
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
