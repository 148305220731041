import React from 'react'

function PageHeader({imageUrl, headingText}) {
  return (
    <div className='pt-50 z-20  bg-slate-50 relative'>
        <img src={imageUrl} className=" object-cover md:h-96 md:w-full"  alt='...'/>
        <div className='bg-black p-2 absolute bottom-3 md:bottom-10 px-3 md:px-20  rounded-r-md'><h1 className='text-skyblue font-bold md:text-3xl'>{headingText}</h1></div>
    </div>
  )
}

export default PageHeader